<template>
    <b-card no-body v-ripple 
            style="cursor: pointer;" 
            @click="view">
        <b-card-body style="padding: 24px;">
            <showAt breakpoint="large">

                <large-view :item="item" :compact="compact" :diff_percent="diff_percent">
                    <template slot="action">
                        <slot name="action"></slot>
                    </template>
                </large-view>

            </showAt>
            <showAt breakpoint="medium">
                <medium-view :item="item" :compact="compact" :diff_percent="diff_percent"> 
                    <template slot="action">
                        <slot name="action"></slot>
                    </template>
                </medium-view>
            </showAt>
            <showAt breakpoint="small">
                <small-view :item="item" :compact="compact" :diff_percent="diff_percent">
                    <template slot="action">
                        <slot name="action"></slot>
                    </template>
                </small-view>
            </showAt>
        </b-card-body>
    </b-card>
</template>

<script>

    import SmallView from "./small"
    import MediumView from "./medium"
    import LargeView from "./large"

    export default {

        data() {
            return {}
        },
        props: {
            item: Object,
            compact: {
                type: Boolean,
                default: false
            },
            diff_percent: {type: Number, default: 0}
        },
        methods: {
            view() {
                if( this.$user.isAuth() ) {
                    this.$router.push({
                        name: 'user-miners-item',
                        params: {
                            item: this.item.id
                       }
                    });
                } else {
                    this.$router.push({
                        name: 'user-miners-buy-item',
                        meta: {
                            pageTitle: this.item.name
                        },
                        params: {
                            item: this.item.id
                       }
                    });
//                    user-miners-buy-item
                }
            }
        },
        components: {
            SmallView,
            MediumView,
            LargeView
        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style>

    .miner-name {
        font-family: Suisse Intl;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
    }
    
    .miner-price {
        font-weight: 500;
        font-size: 22px;
        line-height: 24px;
    }
    
    .miner-info {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
    }
    
    .miner-info li {
        margin-bottom: 4px;
    }
    
</style>