<template>
    
    <div class="d-flex justify-content-between">
        
        <div :style="mediaViewStyle()">
             <b-badge
                v-if="item.recommend"
                variant="success"
                style="color: #FFF; background: #3ED085; border-radius: 50px; align-items: center; padding: 4px 12px;"
            >
                {{$t('catalog.recommend')}}
            </b-badge>
        </div>
        
        <div class="d-flex flex-column justify-content-center" style="width: 100%; padding: 0px 32px;">
            <p class="miner-name">{{ item.name }}</p>
            <div class="d-flex miner-info">
                <div style="padding-right: 16px; border-right-width: 1px;" class="with-seperator">
                    <ul class="list-unstyled">
                        <li><span class="text-muted">{{$t('catalog.hashrate')}}:</span> {{ item.hashrate }} <span class="text-muted">TH/s</span> </li>
                        <li><span class="text-muted">{{$t('catalog.energy-consumption')}}:</span> {{ item.energy_usage }} W </li>
                        <li><span class="text-muted">{{$t('catalog.currency')}}:</span> BTC </li>
                    </ul>
                </div>
                <div class="delimiter"></div>
                <div style="padding-left: 16px;">
                    <ul class="list-unstyled">
                        <li><span class="text-muted">{{$t('catalog.income-per-day')}}:</span> {{ daily_profit }} BTC ≈ {{ daily_currency }} $</li>
                        <li><span class="text-muted">{{$t('catalog.rate-of-return')}}:</span> {{ payback }} </li>
                        <li><span class="text-muted">{{$t('catalog.start-mining')}}:</span> {{$t('catalog.in')}} 30 {{$t('catalog.days')}} </li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="d-flex flex-column justify-content-center" style="min-width: 175px;">
            <p class="miner-price">{{ item.price_amount.formatMoney(0, "", " ") }} <span class="text-muted">$</span></p>
            <slot name="action"></slot>
        </div>
        
    </div>
    
</template>

<script>
    
    import Contracts from "@/modules/user/contracts"
    import Utils from "@/modules/utils/"

    export default {

        data() {
            return {
                period: 37,
                market_price_percent: 3
            }
        },
        props: {
            item: Object,
            diff_percent: {type: Number, default: 0},
        },
        methods: {
            mediaViewStyle() {
                return {
                    height: '150px',
                    width: '150px',
                    minWidth: '150px',
                    backgroundImage: `url(${this.media.urls.thumb})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: "white",
                    borderRadius: "12px"
                }
            },

        },
        components: {

        },
        watch: {

        },
        
        computed: {
            
            payback() {
                let decl = [ 'день', 'дня', 'дней' ];

                if (this.$i18n.locale !== 'ru') decl = [ 'day', 'days', 'days' ];

                let total_profit_usd = this.period_profit * this.market_price_usd - this.fee_usd - this.energy_usd - this.service_usd;

                let aver_usd = total_profit_usd / this.item.service_period;

                // окупаемость
                let days = parseInt((this.item.price_amount) / aver_usd);

                return days + ' ' + Utils.declOfNum( days, decl);   
            },
            
            daily_currency_payback() {
               return (this.daily_profit * this.$blockchain.tickers.values["USD"].last);  
            },

            daily_currency() {
               return (this.daily_profit * this.$blockchain.tickers.values["USD"].last).formatMoney(2, "", " ");  
            },
            
            daily_profit() {
                return ( ((84600 * Contracts.values.R * ( Contracts.values.H * this.item.hashrate )) / ( Contracts.values.D * Math.pow(2, 32) )) ).toFixed(8);
            },

            difficult() {
                let D = parseInt(this.$user.contracts.values.D);

                let temp = D * (this.diff_percent / 100);
                let growing = temp * this.period;
                
                var diff = D + growing;
                                
                if( diff <= 20000000000 ) {
                    diff = 20000000000;
                }
                                
                return parseInt(diff);
                
            },

            market_price_usd() {
                return this.$blockchain.tickers.values.USD.last * (Math.pow((1 + this.market_price_percent / 100), this.period))
            },
            
            energy_period() {
                let count = 1;
                
                if( this.ifIsInCart ) {
                   count = this.miners.cart[this.miner.id].count;              
                }

                if (User.currency === 'BTC') {
                    if (this.market_price_percent == 0) {
                        return (((((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) * this.miner.service_period ) * count) / this.$blockchain.tickers.values['USD'].last
                    } else {
                        // const month_period = 30;
                        // return (((month_period * (1 + (this.market_price_percent / 100)) * (1 - Math.pow(1 + (this.market_price_percent / 100), -(this.miner.service_period / 30.5 - 1) ))) / this.$blockchain.tickers.values['USD'].last)) *36 * count;

                        let energy_btc = 0;
                        let usd_rate = this.$blockchain.tickers.values['USD'].last;

                        let month_energy_usage_usd = ((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 * 30.5;

                        for (let i = 0; i < parseInt(this.miner.service_period / 30); i++) {
                            energy_btc += month_energy_usage_usd / usd_rate;
                            usd_rate = usd_rate * (1 + (this.market_price_percent / 100));
                        }

                        return energy_btc * count;
                    }
                } else {
                    return ((((this.miner.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) * this.miner.service_period ) * count
                }
            },

            energy_usd() {
                let count = 1;
                
                if( this.ifIsInCart ) {
                    count = this.miners.cart[this.miner.id].count;              
                }

                return ((((this.item.energy_usage / 1000) * this.$user.contracts.values.K) * 24 ) * this.item.service_period ) * count
            },

            fee_period() {
                return (this.period_profit * 0.025) * this.market_price;
            },

            fee_usd() {
                return (this.period_profit * 0.025) * this.market_price_usd;
            },

            service_usd() {
                return (this.period_profit * 0.05) * this.market_price_usd;
            },
            
            service_period() {
                return (this.period_profit * 0.05) * this.market_price;
            },

            period_profit() {
                // return ((86400 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.item.hashrate )) / ( this.difficult * Math.pow(2, 32) ));

                
                let profit = 0;
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let diff = parseInt(this.$user.contracts.values.D);

                for (let i = 0; i < this.period; i++) {
                   profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.item.hashrate )) / ( diff * Math.pow(2, 32) );

                   diff += temp;
                }

                return profit;
            },
            
            media() {
                return this.item.medias[0];
            },

            comission() {
                return this.daily_currency_payback * 0.025 ;
            },

            service() {
                return (this.daily_currency_payback - this.comission) * 0.05;                
            },

            market_price() {
                // формула вычета сложно процента 
                if (User.currency === 'USD') {
                    return this.$blockchain.tickers.values[User.currency].last * (Math.pow((1 + this.market_price_percent / 100), this.period))
                } else if  (User.currency === 'RUS') {
                    return this.$blockchain.tickers.values[User.currency].last;
                } else {
                    return 1;
                }
            },

            market_price_usd() {
                return this.$blockchain.tickers.values.USD.last * (Math.pow((1 + this.market_price_percent / 100), this.period))
            },
            
        },
        mounted() {
        }

    }

</script>